import React from "react";
// Customizable Area Start

import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar'
import { logo, upArrow, down, location, logout, catalogue, orderhistory, notifications, summary, legal, settings, left, right, order, support, dish, cartIcon, notificationIcon } from "./assets"
import { styled, Typography, Button } from "@mui/material";
import { Link, Route, Switch } from 'react-router-dom';
import Dishes from "./Dishes.web";

import Notifications from "../../../blocks/notifications/src/Notifications.web";
import DishDetails from "./DishDetails.web";
import UserDashboardController, {
    Props,
} from "./UserDashboardController.web";
import Bakers from "../../../blocks/dashboard/src/Bakers.web";
import LocationUser from "../../../blocks/dashboard/src/LocationUser.web";
import Support from "./Support.web";
import OrderInprogress from "../../../blocks/ordermanagement/src/OrderInprogress.web";
import MyAddresses from "../../../blocks/location/src/MyAddresses.web";
import EditMyAddresses from "../../../blocks/location/src/EditMyAddresses.web";
import UserSettings from "../../settings2/src/UserSettings.web";
import UserLegal from "../../settings2/src/UserLegal.web";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import OrderDetails from "../../ordermanagement/src/OrderDetails.web";
import UserOrderDetails from "../../ordermanagement/src/UserOrderDetails.web";
import UserPayments from "../../stripepayments/src/UserPayments.web";
import ShoppingCart from "../../shoppingcart/src/ShoppingCartOrders.web";
export default class UserDashboard extends UserDashboardController {
    constructor(props: Props) {
        super(props);

    }
    menuItems = [
        { path: "/menu/dishes", label: "Dishes", icon: dish, localStorageTab: "0", testId: "dishes" },
        { path: "/menu/bakers", label: "Bakers", icon: catalogue, localStorageTab: "1", testId: "bakers" },
        { path: "/menu/userorderProgress", label: "Order in Progress", icon: order, testId: "orderProgress" },
        { path: "/menu/userHistory", label: "Orders History", icon: orderhistory, testId: "orderHistory" },
        { path: "/menu/userAddress", label: "My Addresses", icon: summary, testId: "address" },
        { path: "/menu/userNotifications", label: "Notifications", icon: notifications, testId: "notifications" },
    ];

    renderMenuItem = ({ path, label, icon, localStorageTab, testId }: any) => (
        <MenuItem
            key={path}
            className={this.state.activeMenuItem === path ? "active" : ""}
            onClick={() => {
                if (localStorageTab !== undefined) {
                    localStorage.setItem("activeTab", localStorageTab);
                }
                this.handleMenuItemClick(path);
            }}
            icon={<img src={icon} alt={label} />}
            component={<Link to={path} />}
            data-test-id={testId}
        >
            <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                {label}
            </Typography>
        </MenuItem>
    );

    render() {
        const { userObj } = this.state;
        const address = userObj.address;
        return (
            <div style={{ backgroundColor: "rgb(0, 0, 0)", height: "100%", overflowY: "scroll" }}>
                <div style={{ margin: "20px" }}>
                    <Switch>
                        <Route path="/menu/Sharebakersinfo" component={Bakers} />
                    </Switch>
                </div>
                <div style={{ margin: "20px", overflowY: "scroll" }}>
                    <Switch>
                        <Route path="/menu/ShareSpeciality" component={DishDetails} />
                    </Switch>
                </div>
                {window.location.pathname !== `/menu/Sharebakersinfo/${this.state.dishId}` && window.location.pathname !== "/menu/ShareSpeciality"
                    && (
                        <div style={{ display: "flex", backgroundColor: "rgb(0, 0, 0)", height: "95%" }}>
                            <div style={{ height: "100%", display: 'flex' }}>
                                <Sidebar
                                    collapsed={this.state.collapsed}
                                    rootStyles={{
                                        [`.${sidebarClasses.container}`]: {
                                            backgroundColor: '#292524',
                                            color: '#FFFFFF',
                                            borderRadius: "8px"
                                        },
                                        border: "1px solid #57534E",
                                        height: "800px",
                                        width: '375px',
                                        fontWeight: "bold",
                                        borderRadius: '0px 0px 8px 0px'
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
                                        <img src={logo} alt="logo" />
                                        {!this.state.collapsed && <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px' }}>
                                            AMATEUR BAKERS
                                        </Typography>}
                                    </div>
                                    <StyledWrapper>
                                        <Menu
                                            menuItemStyles={{
                                                button: ({ level, active, disabled }) => {
                                                    return {
                                                        borderRadius: "8px",
                                                        padding: "0px 8px",
                                                        margin: "10px 14px",
                                                        "&:hover": {
                                                            backgroundColor: "#57534E",
                                                            fontWeight: "bold",
                                                        },
                                                        cursor: 'pointer'
                                                    };
                                                },
                                            }}
                                        >
                                            {this.menuItems.map(this.renderMenuItem)}
                                            <hr style={{ color: "#57534E" }} />
                                            {this.renderMenuItem({ path: "/menu/legalUser", label: "Legal", icon: legal, testId: "legal" })}
                                            {this.renderMenuItem({ path: "/menu/userSettings", label: "Settings", icon: settings, testId: "settings" })}
                                            {this.renderMenuItem({ path: "/menu/support", label: "Support", icon: support, testId: "support" })}

                                            <MenuItem style={{ border: "1px solid #57534E", padding: "0px" }}
                                                onClick={() => {
                                                    this.setState({ open: true })
                                                }}
                                                data-test-id="logout"
                                                icon={<img src={logout} alt="logout" />}
                                            >
                                                <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>{!this.state.collapsed && "Log Out"}</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </StyledWrapper>
                                </Sidebar>

                                <Dialog
                                    sx={{
                                        borderRadius: '8px',
                                        '& .MuiDialog-paper': {
                                            backgroundColor: '#000000',
                                        },
                                        padding: '40px 16px',
                                    }}
                                    data-test-id="dialogueBox"
                                    open={this.state.open}
                                    aria-describedby="decline-modal-description"
                                    onClose={this.handleClose}
                                    aria-labelledby="decline-modal-title"
                                >
                                    <DialogTitle id="decline-modal-title">
                                        <Typography variant="h6" sx={{
                                            fontSize: '32px',
                                            fontWeight: 700,
                                        }}><span style={{ color: "#ffffff" }}>
                                                Are you sure you want
                                            </span>
                                            <br />
                                            <span style={{ color: "#C2B30A" }}>
                                                to Log out?
                                            </span>
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            padding: "5px 5px",
                                            fontWeight: 400,
                                            color: "#ffffff",
                                            textAlign: "center"
                                        }} variant="body1">
                                            We will be happy to having you again
                                        </Typography>
                                    </DialogTitle>

                                    <DialogActions
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '16px',
                                        }}
                                    >
                                        <Menu>
                                            <MenuItem
                                                component={<Link to="/" />}
                                                onClick={this.handleLogout}
                                                style={{ backgroundColor: "black" }}
                                                data-test-id="deletebtn"
                                            >
                                                <Button
                                                    sx={{
                                                        height: '55px',
                                                        width: '343px',
                                                        borderRadius: '8px',
                                                        textTransform: 'none',
                                                        padding: '16px 24px',
                                                        backgroundColor: '#C2B30A',
                                                        color: '#ffffff',
                                                        '&:hover': {
                                                            backgroundColor: '#a59a00',
                                                        },
                                                        fontWeight: 700,
                                                        fontSize: '18px',
                                                    }}
                                                >Log out</Button>
                                            </MenuItem>

                                        </Menu>

                                        <Button
                                            onClick={this.handleClose1}
                                            sx={{
                                                height: '55px',
                                                width: '343px',
                                                borderRadius: '8px',
                                                textTransform: 'none',
                                                padding: '16px 24px',
                                                backgroundColor: 'black',
                                                color: '#ffffff',
                                                '&:hover': {
                                                    backgroundColor: '#333333',
                                                },
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                border: "1px solid white"
                                            }}
                                            data-test-id='cancelBtn'
                                        >Cancel</Button>
                                    </DialogActions>
                                </Dialog>
                                <img data-test-id="icon" onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                                    style={{
                                        position: "relative",
                                        right: this.state.collapsed ? "10%" : "5%",
                                        top: "45%",
                                        zIndex: "99",
                                        height: "25px",
                                        width: "25px"
                                    }}
                                    src={this.state.collapsed ? right : left} />
                            </div>
                            <div className="content" style={contentStyle}>
                                <div style={headerStyle}>
                                    <Typography variant='subtitle1' style={{
                                        color: "#C2B30A", fontWeight: "700", textTransform: "uppercase",
                                        fontSize: "16px",
                                        textAlign: "left"
                                    }}>{this.state.path}</Typography>
                                    <div>
                                        <Typography>
                                            Your Address:
                                        </Typography>
                                        <div style={{ position: "relative", width: "auto", maxWidth: "17rem" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    padding: "8px",

                                                    borderRadius: "5px",
                                                }}
                                                onClick={this.toggleAddress}
                                                data-test-id="address1"
                                            >
                                                <img style={{ marginRight: "10px", marginTop: "-5px" }} src={location} alt="locationIcon" />
                                                <Typography>
                                                    {this.truncateAddress(this.state.selectedAddress, 20)}
                                                </Typography>
                                                <img data-test-id="arrowIcon" src={this.state.showDropdown ? upArrow : down} alt="arrowIcon" />
                                            </div>

                                            {this.state.showDropdown && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: 0,
                                                        width: "100%",
                                                        background: "#fff",
                                                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                                        borderRadius: "5px",
                                                        zIndex: 10,
                                                        padding: "5px",
                                                    }}
                                                >
                                                    {this.state.userObj.address?.map((addr: any, index: any) => (
                                                        <div
                                                            key={addr.id}
                                                            style={{
                                                                padding: "8px",
                                                                cursor: "pointer",
                                                                color: "black",
                                                                borderBottom: index !== this.state.userObj.address.length - 1 ? "1px solid #ddd" : "none",
                                                            }}
                                                            onClick={() => this.selectAddress(addr.address)}
                                                            data-test-id="selectaddress1"
                                                        >
                                                            <Typography>
                                                                {addr.address}
                                                            </Typography>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div style={{ marginRight: "5rem", display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "30px" }}>
                                        <Link to="/menu/userNotifications">
                                            <img src={notificationIcon} alt="notificationIcon" />
                                        </Link>
                                        <Menu>
                                            <MenuItem
                                                className={this.state.activeMenuItem === "/menu/dishes" ? "active" : ""}
                                                onClick={() => {
                                                    localStorage.setItem("activeTab", "0");
                                                    this.handleMenuItemClick("/menu/dishes");
                                                    this.setState({ openCart: true })
                                                }}
                                                style={{ backgroundColor: "transparent" }}
                                                data-test-id="cartIcon"
                                            >
                                                <img alt="cartIcon" src={cartIcon} />
                                            </MenuItem>
                                        </Menu>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
                                            <img src={this.state.profile_pic || "https://static-00.iconduck.com/assets.00/profile-circle-icon-512x511-27c2rv9p.png"} alt='profile-pic' style={{ backgroundColor: "white", width: "50px", height: "50px", borderRadius: "50%" }} />
                                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                                                <Typography variant='subtitle2' style={{
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    lineHeight: '17.78px',
                                                    textAlign: 'left'
                                                }}>{this.state.userObj.first_name} {this.state.userObj.last_name}</Typography>
                                                <Typography variant='body2'>{this.state.userObj.email}</Typography>
                                            </div>
                                        </div>
                                    </div>


                                    {this.state.openCart &&
                                        <ShoppingCart
                                            history={this.props.history}
                                            navigation={this.props.navigation}
                                            id=""
                                            openCart={this.state.openCart}
                                            data-test-id="cart"
                                            onClose={() => this.setState({ openCart: false })}
                                        />
                                    }

                                </div>
                                <div style={routingStyle}>
                                    <Switch>
                                        <Route path="/menu/dishes" exact component={Dishes} />
                                        <Route path="/menu/bakers" exact component={Dishes} />
                                        <Route path="/menu/bakersinfo" exact component={Bakers} />
                                        <Route path="/menu/locationuser" exact component={LocationUser} />
                                        <Route path="/menu/support" exact component={Support} />
                                        <Route path="/menu/userorderProgress" exact component={OrderInprogress} />
                                        <Route path="/menu/userNotifications" exact component={Notifications} />
                                        <Route exact path="/menu/userAddress" component={MyAddresses} />
                                        <Route path="/menu/userAddress/:addressId" component={EditMyAddresses} />
                                        <Route path="/menu/userSettings" exact component={UserSettings} />
                                        <Route path="/menu/dish-Details" exact component={DishDetails} />
                                        <Route path="/menu/legalUser" exact component={UserLegal} />
                                        <Route path="/menu/userHistory" exact component={OrderDetails} />
                                        <Route path="/menu/userHistoryDetails/:id" exact component={UserOrderDetails} />
                                        <Route path="/menu/UserPayments" exact component={UserPayments} />
                                    </Switch>
                                </div>

                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

const StyledWrapper = styled(Menu)({
    "& .active": {
        backgroundColor: "#57534E",
        color: "#C2B30A",
        fontWeight: "bold",
        borderRadius: "8px",
        margin: "10px 14px",
    },
    "& li.active": {
        width: "auto",
        "& a": {
            marginLeft: "0"
        }
    },
    "& .active img": {
        filter: "brightness(0) saturate(100%) invert(54%) sepia(36%) saturate(547%) hue-rotate(10deg) brightness(99%) contrast(99%)"
    }

})
const headerStyle = {
    backgroundColor: "#292524",
    height: "79px",
    padding: "16px 32px 16px 32px",
    borderRadius: "8px 8px 8px 8px",
    margin: "0px 25px 25px 0px",
    transition: 'margin-left 0.3s ease',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#ffffff",
};

const contentStyle: React.CSSProperties = {
    flex: "1 1 auto",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    overflowX: "hidden",
};

const routingStyle = {
    ...headerStyle,
    height: "auto",
};

// Customizable Area End
