import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AlertColor } from "@mui/material";

export interface Props {
    navigation: any;
    id: string;
    history: { push: any };
    location: any;
}

interface S {
    token: string;
    tab: string;
    quantity: number,
    tip: string,
    cartItems: any[],
    open: boolean,
    openMenu: boolean,
    dishes: any[],
    selectedDish: any,
    openSuccess: boolean,
    costSummary: {
        products: number,
        tips: number,
        fee: number,
    },
    clientDetails: {
        firstName: string,
        lastName: string,
        phoneNumber: string,
        date: string
    },
    orderId: any,
    showToast: boolean,
    toastMessage: string,
    toastSeverity: AlertColor;
    isCrossSellingShown: boolean,
    customTip: string,
    isCustomTipModalOpen: boolean
}

interface SS {
    id: any;
}

export default class PaymentsController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    getOrderDetailsApiCallId: any = "";
    apiMostPopularDishesCallId: any = "";
    addToCartApi: any = "";
    proceedtoPay: any = "";
    changeCartApi: any = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        this.state = {
            token: "",
            tab: "1",
            quantity: 1,
            tip: "",
            open: false,
            cartItems: [],
            openSuccess: false,
            openMenu: false,
            dishes: [],
            selectedDish: null,
            costSummary: {
                products: 0,
                tips: 0,
                fee: 0
            },
            clientDetails: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                date: ""
            },
            orderId: null,
            showToast: false,
            toastMessage: '',
            toastSeverity: 'success' as AlertColor,
            isCrossSellingShown: false,
            customTip: "",
            isCustomTipModalOpen: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {
        console.log("api_Key", process.env.REACT_APP_STRIPE_API_KEY)
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        super.componentDidMount();
        const orderId = localStorage.getItem("order_id") || null
        this.setState({ orderId })
        this.getCartItems(orderId)
        this.getMostPopularDishes();
        const cartItems = JSON.parse(localStorage.getItem("cartItems") || "[]");
        this.setState({ cartItems });
        this.checkPaymentStatus();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.search !== prevProps.location.search) {
            const queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams.has("success") || queryParams.has("canceled")) {
                this.checkPaymentStatus();
            }
        }
    }



    async receive(from: string, message: Message) {
        let apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.apiMostPopularDishesCallId) {
            this.handleMostPopularDishes(responseJson);
        }

        if (apiRequestCallId === this.addToCartApi) {
           this.handleAddtoCart(responseJson)
        }

        if (apiRequestCallId === this.getOrderDetailsApiCallId) {
           this.handleOrderDetails(responseJson);
        }

        if (apiRequestCallId === this.changeCartApi) {
            if (responseJson.error) {
                this.handleShowToast(responseJson.error, "error");
            } else {
                const orderId = localStorage.getItem("order_id") || null
                this.getCartItems(orderId)
            }
        }
        

    }

    handleAddtoCart = (responseJson: any) => {
        if (responseJson.errors) {
            this.handleShowToast(responseJson.errors, "error");
        } else {
            this.setState({ openMenu: false }, () => {
                this.getCartItems(this.state.orderId)
            })
        }
    }

    checkPaymentStatus = () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        const success = queryParams.get("success");
        const canceled = queryParams.get("canceled");

        if (success) {
            this.setState({ openSuccess: true });
        } else if (canceled) {
            this.setState({ open: true });
        }
    };

    handleChange = (e: any, newValue: any) => {
        this.setState({ tab: newValue });
    };

    handleTipChange = (event: any) => {
        if (!event || !event.target) return;
        const selectedTip = event.target.value;
        if (selectedTip === "custom") {
            this.setState({ isCustomTipModalOpen: true });
        } else {
            this.setState({ tip: selectedTip, customTip: "" });
        }
    };

    handleCustomTipChange = (event: any) => {
        this.setState({ customTip: event.target.value });
    };
    
    handleCustomTipSubmit = () => {
        const { customTip } = this.state;
        const tipAmount = parseFloat(customTip);
        
        if (!isNaN(tipAmount) && tipAmount >= 0) {
            this.setState({ 
                tip: tipAmount.toFixed(2),
                isCustomTipModalOpen: false 
            });
        }
    };
    

    handleSelect = (event: any) => {
        this.setState({ token: event.target.value });
    }
    
    handleSelectDish = (id: any) => {
        this.setState({ selectedDish: id });
    };

    handleClose = () => {
        this.setState({ openMenu: false })
    };

    handleNotforNow = () => {
        this.setState({isCrossSellingShown: true, openMenu: false})
    }

    getCartItems = (id: any) => {
        let token = localStorage.getItem("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getOrderDetailsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getOrderDetails}?order_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.listOfOrdersMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    getMostPopularDishes = () => {
        let token = localStorage.getItem("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiMostPopularDishesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.mostPopularDishesEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.listOfOrdersMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    handleMostPopularDishes = (responseJson: any) => {
        const dishes = responseJson.data.map((item: any) => ({
            id: item.id,
            price: item.attributes.price,
            title: item.attributes.name,
            bakerFirstName: item.attributes.accounts.attributes.first_name,
            bakerLastName: item.attributes.accounts.attributes.last_name,
            rating: item.attributes.average_rating,
            dishImg: item.attributes.images?.map((img: any) => img.url),
            time: item.attributes.estimated_time,
        }));
        this.setState({ dishes })
    }

    handleOrderDetails = (responseJson: any) => {
        const cartItems = responseJson.order_items.map((item: any) => ({
            id: item.id,
            imageUrl: item.attributes.catalogue.data?.attributes?.images,
            name: item.attributes.catalogue.data.attributes.name,
            description: item.attributes.catalogue.data.attributes.description,
            price: item.attributes.price,
            discountedPrice: item.attributes.taxable_value,
            other_charges: item.attributes.other_charges,
            quantity: item.attributes.quantity
        }));

        const accountAttributes = responseJson.order_items[0].attributes.catalogue.data.attributes.accounts.attributes;

        const clientDetails = {
            firstName: accountAttributes.first_name,
            lastName: accountAttributes.last_name,
            phoneNumber: accountAttributes.phone_number,
            date: new Date(accountAttributes.updated_at).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        };

        const { price, discountedPrice, other_charges } = cartItems.reduce(
            (acum: any, cur: any) => ({
                price: acum.price + cur.price * cur.quantity,
                discountedPrice: acum.discountedPrice + cur.discountedPrice,
                other_charges: acum.other_charges + cur.other_charges,
            }),
            { price: 0, discountedPrice: 0, other_charges: 0 }
        );
        this.setState({
            cartItems,
            clientDetails,
            costSummary: { products: price, tips: other_charges, fee: discountedPrice }
        });
    }

    handlePay = () => {
        if (!this.state.isCrossSellingShown && this.state.dishes.length > 1) {
            this.setState({ openMenu: true });
        }
    };

    addToCart = () => {
        let token = localStorage.getItem("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": token
        };
        const attrs: { [key: string]: any } = {
            catalogue_id: this.state.selectedDish,
            quantity: 1,
        };
        const httpBody = {
            order_items: {
                ...attrs,
            },
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addToCartApi = requestMessage.messageId;
        requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addToCartApi
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.confirmPaymentMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleShowToast = (message: string, severity: AlertColor) => {
        this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
    }

    handleCloseToast = () => {
        this.setState({ showToast: false });
    }

    updateQuantityLocally = (itemId: any, newQuantity: any) => {
        this.setState((prevState) => ({
            cartItems: newQuantity > 0
                ? prevState.cartItems.map((item) =>
                    item.id === itemId ? { ...item, quantity: newQuantity } : item
                )
                : prevState.cartItems.filter((item) => item.id !== itemId),
        }));

        const updatedCart = newQuantity > 0
            ? this.state.cartItems.map((item) =>
                item.id === itemId ? { ...item, quantity: newQuantity } : item
            )
            : this.state.cartItems.filter((item) => item.id !== itemId);

        localStorage.setItem("cartItems", JSON.stringify(updatedCart));
        this.updateCartItems(itemId, newQuantity)
    };

    updateCartItems = (id: any, newQuantity: any) => {
        let token = localStorage.getItem("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": token
        };
        const attrs: { [key: string]: any } = {
            quantity: newQuantity,
        };
        const httpBody = {
            order_items: {
                ...attrs,
            },
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.changeCartApi = requestMessage.messageId;
        requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addToCartApi}/${id}/update_quantity`
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.confirmPaymentMethod1
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    calculateTotal = () => {
        const { cartItems } = this.state;
        const productsTotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
        const fee = productsTotal * 0.02;    
        const tax = productsTotal * 0.0825;
        return (productsTotal + fee + Number(this.state.tip) + tax).toFixed(2);
    };
    
    calculateTax = () => {
        const productsTotal = this.state.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
        return productsTotal * 0.0825;
    };

    calculateTip = (value: any) => {
        const productsTotal = this.state.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
        return productsTotal * value/100;
    }
    
    


}
