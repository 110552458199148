import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

interface OrderDetail {
  status: string;
  id: number;
  bakerName: string;
  date: string;
  pickup: string;
  images: string[];
  total: number;

}

interface UserOrderDetails {
  id: number;
  firstName: string;
  lastName: string;
  orderCreatedAt: string;
  timeToPickUp: string;
  images: string[];
}

interface CartItem {
  id: number;
  imageUrl: any[];
  name: string;
  description: string;
  price: number;
  discountedPrice: number;
  other_charges: number;
  quantity: number;
  extras: { id: number; name: string; price: number }[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: { push: any };
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bakers: OrderDetail[];
  filteredBakers: OrderDetail[];
  searchQuery: string,
  page: number,
  cartItems: CartItem[],
  itemsPerPage: number,
  costSummary: {
    products: number,
    tips: number,
    fee: number,
  },
  selectedValue: string,
  open: boolean
  pages: number
  userOrderDetails: UserOrderDetails[]
  openOrderDetails: boolean
  orderId: any
  status: string;
  firstName: string;
  lastName: string;
  orderCreatedAt: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserOrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderHistoryApiCallId: any = "";
  getOrderDetailsApiCallId: any = "";
  getUserOrderDetailsApiCallId: any = "";
  id = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedValue: 'newDatesc',
      open: false,
      searchQuery: "",
      bakers: [],
      filteredBakers: [],
      page: 1,
      itemsPerPage: 10,
      cartItems: [],
      costSummary: {
        products: 0,
        tips: 0,
        fee: 0
      },
      pages: 1,
      userOrderDetails: [],
      openOrderDetails: false,
      status: '',
      firstName: '',
      lastName: '',
      orderId: '',
      orderCreatedAt: ''
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    super.componentDidMount();
    const id =
      this.props.location.pathname.match(/\/userOrderDetails\/(\d+)/) ||
      this.props.location.pathname.match(/\/menu\/userHistoryDetails\/(\d+)/);
      const orderId = id ? id[1] : null;
    if (orderId == null) {
      this.getOrderHisory();
    } else {
      this.getOrderDetails(orderId)
    }
    const user = localStorage.getItem("user_type")
    if(user !== "chef"){
      this.getUserOrderDetails();
    }
    this.updateFilteredBakers();

    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  // Web Event Handling

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getOrderHistoryApiCallId) {
      const orderDetails = responseJson?.data.map((order: any) => ({
        id: order.id,
        bakerName: order.attributes.customer.data.attributes.first_name,
        date: order.attributes.date,
        pickup: order.attributes.pick_up,
        status: order.attributes.status,
        images: order.attributes.order_items.data[0].attributes.catalogue.data?.attributes.images,
        total: this.getTotalPrice(order.attributes.order_items.data)
      }));
      this.setState({
        bakers: orderDetails,
        filteredBakers: orderDetails,
        pages: Math.ceil(responseJson.meta.pagination.records / this.state.itemsPerPage)
      });

    }

    if (apiRequestCallId === this.getOrderDetailsApiCallId) {

      const status = responseJson.order_status;
      const firstName = responseJson.order_items?.[0]?.attributes?.customer?.data?.attributes?.first_name ;
      const lastName = responseJson.order_items?.[0]?.attributes?.customer?.data?.attributes?.last_name;
      const orderCreatedAt = responseJson.order_items?.[0]?.attributes.order_created_at;
      const cartItems = responseJson.order_items.map((item: any) => ({
        id: item.id,
        imageUrl: item.attributes.catalogue.data?.attributes?.images,
        name: item.attributes.catalogue.data?.attributes?.name,
        description: item.attributes.catalogue.data?.attributes?.description,
        price: item.attributes?.price,
        discountedPrice: item.attributes?.taxable_value,
        other_charges: item.attributes?.other_charges,
        quantity: item.attributes?.quantity,
        extras: item.attributes.catalogue.data?.attributes?.extras?.map((extra: any) => ({
          id: extra.id,
          name: extra.name,
          price: extra.price
        })),
      }));


      const { price, discountedPrice, other_charges } = cartItems.reduce(
        (acum: any, cur: any) => ({
          price: acum.price + cur.price * cur.quantity,
          discountedPrice: acum.discountedPrice + cur.discountedPrice,
          other_charges: acum.other_charges + cur.other_charges,
        }),
        { price: 0, discountedPrice: 0, other_charges: 0 }
      );
      this.setState({
        cartItems,
        status, firstName, lastName, orderCreatedAt,
        costSummary: { products: price, tips: other_charges, fee: price * 0.02, }
      });
    }


    if (apiRequestCallId === this.getUserOrderDetailsApiCallId) {      
      const userOrderDetails = responseJson?.data.map((item: any) => {
        const orderItems = item.attributes.order_items?.data || [];
    
        if (orderItems.length === 0) {
          return null;
        }
    
        const firstOrderItem = orderItems[0];
        const orderCreatedAt = firstOrderItem.attributes?.order_created_at 
          ? new Date(firstOrderItem.attributes.order_created_at) 
          : null;
    
        return {
          id: item.id,
          firstName: firstOrderItem.attributes.catalogue.data.attributes.accounts.attributes.first_name,
          lastName: firstOrderItem.attributes.catalogue.data.attributes.accounts.attributes.last_name,
          orderCreatedAt,
          timeToPickUp: firstOrderItem.attributes.time_to_pick_up,
          images: firstOrderItem.attributes.catalogue.data.attributes.images.map((img: any) => img.url),
        };
      }).filter(Boolean);    
      userOrderDetails.sort((a: any, b: any) => (b.orderCreatedAt?.getTime()) - (a.orderCreatedAt?.getTime()));
    
      userOrderDetails.forEach((order: any) => {
        if (order.orderCreatedAt) {
          order.orderCreatedAt = order.orderCreatedAt.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
        }
      });
    
      this.setState({
        userOrderDetails,
        pages: Math.ceil(responseJson.data.length / this.state.itemsPerPage),
      });
    }
    
    
    // Customizable Area End
  }
  // Customizable Area Start
  handleSearchChange = (event: any) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });

  };

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
  };

  calculateItemTotal = (order: any) => {
    return order.quantity * Number(order.price);
  };

  calculateExtrasTotal = (order: any) => {
    return Array.isArray(order.extras)
      ? order.extras.reduce((sum: any, extra: any) => sum + Number(extra.price || 0), 0) * order.quantity 
      : 0;
  };
  calculateTotalPrice = () => {
    const subtotal = this.state.cartItems.reduce((total, order) => {
        const itemTotal = this.calculateItemTotal(order);
        const extrasTotal = this.calculateExtrasTotal(order);
        return total + itemTotal + extrasTotal;
    }, 0);

    const fee = subtotal * 0.02; 
    const tax = subtotal * 0.0825;

    return { subtotal, fee, tax, total: subtotal + fee + tax+ 1.8 };
};


  openPopup = (id: any) => {
    this.getOrderDetails(id)
    this.setState({ orderId: id, openOrderDetails: true })
  }

  handleClose = () => {
    this.setState({ openOrderDetails: false })
  }
  debouncedRequest = (() => {
    let timer: any;

    return (event: any) => {
      clearTimeout(timer);
      const searchQuery = event.target.value;
      this.setState({ searchQuery });
      timer = setTimeout(() => { this.getOrderHisory() }, 300);
    };
  })()

  applyFilter = () => {
    this.getOrderHisory();
    this.setState({ open: false });
  }
  getTotalPrice = (arr: any[]) => {
    return arr.reduce((acum, cur) => acum + cur.attributes.price * cur.attributes.quantity, 0);
  };  
  getOrderHisory = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderHistoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderHistory}?sort_by=${this.state.selectedValue}&page[number]=${this.state.page}&page[size]=${this.state.itemsPerPage}&search=${this.state.searchQuery}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getOrderDetails = (id: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetails}?order_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  gotoCartItems = (id: any) => {
    this.props.history.push(`/userOrderDetails/${id}`);
  }
  handleChange = (event: any) => {
    this.setState({ selectedValue: event.target?.value });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };
  handlePaginationChange = (event: any, value: any) => {
    this.setState({ page: value }, () => {
      this.getOrderHisory();
    });
  };

  updateFilteredBakers = () => {
    const { bakers, searchQuery, page, itemsPerPage } = this.state;
    const dataToPaginate = searchQuery ? this.state.filteredBakers : bakers;
    const startIndex = (page - 1) * itemsPerPage;
    const paginatedData = dataToPaginate.slice(startIndex, startIndex + itemsPerPage);
    this.setState({ filteredBakers: paginatedData });
  }

  getUserOrderDetails = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserOrderDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserOrderDetails}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  goToOrderHistory = (id: any) => {
    this.props.history.push(`/menu/userHistoryDetails/${id}`)
  }
  // Customizable Area End
}